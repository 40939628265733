import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';

import { App } from './App';
import { msalConfig } from './authConfig';

import './styles/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({ basename: '' });

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=0d49458d-1bec-41b7-88a1-667f2fd84a5c;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=2cb2772f-4dbe-47c9-9c00-b3970b3e0724',
        //connectionString: 'InstrumentationKey=8f632a92-f8f3-4518-b388-9bd638cb016a;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=708149fd-c7de-4b5a-8194-e844c79f3cf5',
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
          },
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        distributedTracingMode: 1,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        disableAjaxTracking: false,
        enableCorsCorrelation: true,
    }
});
appInsights.addTelemetryInitializer((envelope) => {envelope.data = envelope.data || {}; envelope.tags["ai.cloud.role"] = "Banchio-React";});
appInsights.addTelemetryInitializer((env) => { if(env.baseType === "PageviewData" || env.baseType === "PageviewPerformanceData") { env.baseData.name = window.location.pathname; } });
appInsights.addDependencyInitializer((dependency) => {if (dependency.item.target.includes("login.microsoftonline.com")) {return false; } return dependency; });

// appInsights.addDependencyInitializer
appInsights.loadAppInsights();

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

/**
 * To set an active account after the user signs in, register an event and listen for LOGIN_SUCCESS and LOGOUT_SUCCESS. For more,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
 */
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App instance={msalInstance} />
        </BrowserRouter>
    </React.StrictMode>
);
